import * as React from 'react';
import Review from '../../component/Review';
import Button from '../../component/Button';
import ReviewList from '../../component/ReviewList';
import ReviewContainer from './styled';
import { navigate } from 'gatsby';

function ReviewsSection() {
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  const handleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  React.useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = ''; // cleanup func
    };
  }, [isOpenModal]); // Function to disable scroll for main page

  return (
    <ReviewContainer id="reviews">
      <ReviewList />
      <Button variant="primary" onClick={handleModal}>
        Залишити відгук!
      </Button>
      <Review handleModal={handleModal} isOpenModal={isOpenModal} />
    </ReviewContainer>
  );
}

export default ReviewsSection;
